import React, { useState, useEffect } from "react";

const LastAction = () => {
    const [lastTask, setLastTask] = useState("");

    useEffect(() => {
        const ws = new WebSocket("wss://kate.homeserver.dns64.de:7777");

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            // Filter messages for task events
            if (data.event === "task") {
                setLastTask(data.lastTask); // Assuming the backend sends an object with a property 'lastTask'
            }
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        // No need to close the WebSocket here as we want to keep listening for updates

        return () => {
            // Clean up WebSocket on component unmount if needed
        };
    }, []);

    const styles = {
        container: {
            marginTop: '-20px',
        },
        heading: {
            fontSize: '24px',
            color: '#333',
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Last task: {lastTask}</h1>
        </div>
    );
};

export default LastAction;
