import React, { useState, useEffect } from "react";

const BingoTable = () => {
    const [randomFields, setRandomFields] = useState([]);

    useEffect(() => {
        const ws = new WebSocket("wss://kate.homeserver.dns64.de:7777");

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            // Filter messages for board and task events
            if (data.event === "board") {
                setRandomFields(data.fields); // Assuming data.fields contains the updated fields
            }
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        return () => {
            ws.close();
        };
    }, []);

    const handleButtonClick = (count) => {
        fetch('https://kate.homeserver.dns64.de:7777/api/increment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ count }),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.message);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const getButtonStyle = (state) => ({
        ...styles.button,
        backgroundColor: state === 0 ? 'grey' : 'yellow',
    });

    // Calculate the width of the grid container based on button size and number of columns
    const numColumns = 5; // Number of columns in the grid
    const buttonSize = 70; // Size of each button in pixels
    const gapSize = 5; // Gap between buttons in pixels

    const gridWidth = numColumns * (buttonSize + gapSize) - gapSize; // Calculate total width with gaps

    return (
        <div style={{ ...styles.gridContainer, width: `${gridWidth}px` }}>
            <div style={styles.grid}>
                {randomFields.map((field, index) => (
                    <button
                        key={index}
                        style={getButtonStyle(field.state)}
                        onClick={() => handleButtonClick(field.count)}
                    >
                        {field.count}
                    </button>
                ))}
            </div>
        </div>
    );
};

const styles = {
    gridContainer: {
        border: "2px solid black",
        borderRadius: "5px",
        padding: "10px",
        maxWidth: "100%",
        margin: "0 auto",
        overflowX: "auto", // Enable horizontal scrolling if content overflows
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "5px",
    },
    button: {
        width: "70px",
        height: "70px",
        padding: "0",
        fontSize: "16px",
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "bold",
    }
};

export default BingoTable;
