import React from "react";
import BingoTable from "../components/BingoTable";
import LastAction from "../components/LastAction";

const Board = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Token Bingo</h1>
            <BingoTable/>
            <LastAction/>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px", // Adjust gap as needed
        marginTop: "0px", // Example margin top for spacing
    },
    heading: {
        textAlign: "center", // Center the text horizontally
        margin: "0", // Remove default margin
        marginBottom: "-10px", // Adjust as needed for vertical spacing
        width: "100%", // Ensure the text is centered to the width of the div
    }
};

export default Board;
