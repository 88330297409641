import React, { useState } from "react";
import BingoTable from "../components/BingoTable";
import LastAction from "../components/LastAction";
import axios from 'axios';

const Settings = () => {
    const [numberInput, setNumberInput] = useState('');
    const [textInputs, setTextInputs] = useState({
        smallTask: '',
        bingoTask: '',
        boardClearedTask: ''
    });

    const handleNumberChange = (e) => {
        setNumberInput(e.target.value);
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setTextInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        const data = {
            number: numberInput,
            texts: textInputs
        };

        axios.post('https://kate.homeserver.dns64.de:7777/api/settings', data)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error("There was an error sending the data!", error);
            });
    };

    return (
        <div className="App">
            <BingoTable />
            <LastAction />
            <div>
                <label>
                    Number:
                    <input type="number" value={numberInput} onChange={handleNumberChange} />
                </label>
                <div>
                    <label>
                        Small Task:
                        <input type="text" name="smallTask" value={textInputs.smallTask} onChange={handleTextChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Bingo Task:
                        <input type="text" name="bingoTask" value={textInputs.bingoTask} onChange={handleTextChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Board Cleared Task:
                        <input type="text" name="boardClearedTask" value={textInputs.boardClearedTask} onChange={handleTextChange} />
                    </label>
                </div>
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default Settings;
